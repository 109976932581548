import PaginationData from "@/models/general/PaginationData";
import EmployeeWorkContract from "./EmployeeWorkContract";
import EmployeeWorkContractsFilter from "./EmployeeWorkContractsFilter";

export default class EmployeeWorkContracts {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.employeeWorkContractsData = [];
    this.employeeWorkContract = new EmployeeWorkContract();
    this.filterData = new EmployeeWorkContractsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.employeeWorkContractsPagination);
    this.employeeWorkContractsData =
      data.employeeWorkContractsPagination.employeeWorkContractsData;
  }
}
