<template>
  <b-modal id="EmployeeWorkContractPrint" scrollable centered hide-footer>
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />
    <template #modal-title>
      <h3>
        <img src="@/assets/images/email.svg" class="icon-lg" />
        {{ $t("viewAndSend") }}
      </h3>
    </template>
    <div class="row">
      <div class="form-label-group col-12">
        <input
          name="sendTo"
          id="sendTo"
          type="text"
          v-model="employeeWorkContractData.sendTo"
          class="form-control"
          placeholder=" "
          maxlength="50"
        />
        <label for="sendTo">{{ $t("email") }}</label>
        <img src="@/assets/images/email.svg" />
      </div>
    </div>
    <div class="modal-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="printEmployeeWorkContract"
      >
        {{ $t("viewAndSend") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EmployeeWorkContractPrint",
  mixins: [createToastMixin],
  components: { PreLoader, ExceptionWithImg },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  methods: {
    async printEmployeeWorkContract() {
      this.isLoading = true;

      try {
        const response =
          await this.employeeWorkContractData.printEmployeeWorkContract(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("EmployeeWorkContractPrint");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  props: ["employeeWorkContractData"],
  computed: {},
};
</script>

<style scoped lang="scss"></style>
