<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div
      v-if="
        employeeWorkContracts.employeeWorkContractsData != undefined &&
        employeeWorkContracts.employeeWorkContractsData.length != 0
      "
    >
      <EmployeeWorkContractsTable
        :employeeWorkContractsData="
          employeeWorkContracts.employeeWorkContractsData
        "
        :employeeWorkContractData="employeeWorkContracts.employeeWorkContract"
        v-on:setEmployeeWorkContractData="
          employeeWorkContracts.employeeWorkContract.fillData($event)
        "
        :pagination="employeeWorkContracts.pagination"
      />
      <EmployeeWorkContractInfo
        :employeeWorkContractData="employeeWorkContracts.employeeWorkContract"
      />
      <EmployeeWorkContractDelete
        :employeeWorkContractData="employeeWorkContracts.employeeWorkContract"
        v-on:refresh="getAllEmployeeWorkContracts()"
      />
      <EmployeeWorkContractPrint
        :employeeWorkContractData="employeeWorkContracts.employeeWorkContract"
      />
      <Pagination
        v-if="!isLoading"
        :paginationData="employeeWorkContracts.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <EmployeeWorkContractFloatBtns
      :theFilterData="employeeWorkContracts.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EmployeeWorkContractsTable from "@/components/employees/employeeWorkContracts/EmployeeWorkContractsTable.vue";
import EmployeeWorkContractInfo from "@/components/employees/employeeWorkContracts/EmployeeWorkContractInfo.vue";
import EmployeeWorkContractDelete from "@/components/employees/employeeWorkContracts/EmployeeWorkContractDelete.vue";
import EmployeeWorkContractPrint from "@/components/employees/employeeWorkContracts/EmployeeWorkContractPrint.vue";
import EmployeeWorkContractFloatBtns from "@/components/employees/employeeWorkContracts/EmployeeWorkContractFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import currentTabMixin from "@/utils/currentTab-mixin";
import EmployeeWorkContracts from "@/models/employees/employeeWorkContracts/EmployeeWorkContracts";

export default {
  name: "EmployeeWorkContracts",
  mixins: [createToastMixin, currentTabMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeWorkContractsTable,
    EmployeeWorkContractInfo,
    EmployeeWorkContractDelete,
    EmployeeWorkContractPrint,
    EmployeeWorkContractFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["employeeToken"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      employeeWorkContractToken: "",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employeeWorkContracts: new EmployeeWorkContracts(),
    };
  },
  methods: {
    changePage(page) {
      this.employeeWorkContracts.pagination.selfPage = page;
      this.getAllEmployeeWorkContracts();
    },
    search(data) {
      this.employeeWorkContracts.filterData.fillData(data);
      this.employeeWorkContracts.employeeWorkContractsData = [];
      this.getAllEmployeeWorkContracts();
    },
    async getAllEmployeeWorkContracts() {
      this.isLoading = true;
      try {
        const response =
          await this.employeeWorkContracts.employeeWorkContract.getAllEmployeeWorkContracts(
            this.language,
            this.userAuthorizeToken,
            this.employeeWorkContracts.pagination,
            this.employeeWorkContracts.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.employeeWorkContracts.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.employeeWorkContracts.employeeWorkContractsData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.employeeWorkContracts.employeeWorkContractsData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.employeeWorkContracts.employeeWorkContractsData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.employeeWorkContracts.employeeWorkContractsData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    if (this.currentTab && this.employeeToken) {
      this.employeeWorkContracts.filterData.employeeToken = this.employeeToken;
    }
    this.getAllEmployeeWorkContracts();
  },
};
</script>
