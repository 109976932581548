<template>
  <div v-if="employeeWorkContractsData" class="custom-tab-card">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("employeeName") }}</th>
            <th>{{ $t("employeeWorkContracts.theJob") }}</th>
            <th>{{ $t("employeeWorkContracts.name") }}</th>
            <th>{{ $t("employeeWorkContracts.code") }}</th>
            <th>{{ $t("ContractForms.name") }}</th>
            <th>{{ $t("employeeWorkContracts.dateTimeStart") }}</th>
            <th>{{ $t("employeeWorkContracts.dateTimeEnd") }}</th>
            <th>{{ $t("employeeWorkContracts.contractSalary") }}</th>
            <th>{{ $t("info") }}</th>
            <th>
              {{ $t("edit") }}
            </th>
            <th>{{ $t("employeeWorkContracts.print") }}</th>
            <th>
              {{ $t("delete") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(employeeWorkContract, index) in employeeWorkContractsData"
            :key="employeeWorkContract.employeeWorkContractToken"
          >
            <td>{{ ++index + pagination.currentIndex }}</td>
            <td>
              {{ isDataExist(employeeWorkContract.employeeNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(employeeWorkContract.employeeJobNameCurrent) }}
            </td>
            <td>
              <span class="cut-3line">
                {{
                  isDataExist(
                    employeeWorkContract.employeeWorkContractNameCurrent
                  )
                }}
              </span>
            </td>
            <td>
              {{ isDataExist(employeeWorkContract.employeeWorkContractCode) }}
            </td>
            <td>
              {{
                isDataExist(
                  employeeWorkContract.contractFormData.contractFormNameCurrent
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  formateDateTimeLang(
                    employeeWorkContract.employeeWorkContractStartDate,
                    employeeWorkContract.employeeWorkContractStartTime
                  )
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  formateDateTimeLang(
                    employeeWorkContract.employeeWorkContractEndDate,
                    employeeWorkContract.employeeWorkContractEndTime
                  )
                )
              }}
            </td>
            <td>
              {{ isDataExist(employeeWorkContract.employeeWorkContractSalary) }}
            </td>
            <td>
              <button
                v-b-modal.EmployeeWorkContractInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setEmployeeWorkContractData(employeeWorkContract)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <router-link
                :to="{
                  name: 'EmployeeWorkContractEdit',
                  params: {
                    employeeWorkContractToken:
                      employeeWorkContract.employeeWorkContractToken,
                  },
                }"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              <button
                v-b-modal.EmployeeWorkContractPrint
                class="btn p-0"
                :title="$t('employeeWorkContracts.print')"
                @click="setEmployeeWorkContractData(employeeWorkContract)"
              >
                <img src="@/assets/images/contracts.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <button
                v-b-modal.EmployeeWorkContractDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setEmployeeWorkContractData(employeeWorkContract)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  setDataMultiLang,
  formateDateTimeLang,
  isDataExist,
} from "@/utils/functions";
import privilegeMixin from "@/utils/privilege-mixin";

export default {
  name: "EmployeeWorkContractsTable",
  mixins: [privilegeMixin],
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: [
    "employeeWorkContractsData",
    "employeeWorkContractData",
    "pagination",
  ],
  methods: {
    setDataMultiLang,
    formateDateTimeLang,
    isDataExist,
    setEmployeeWorkContractData(employeeWorkContractData) {
      this.$emit("setEmployeeWorkContractData", employeeWorkContractData);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
